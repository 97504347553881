<template src="./error403.html" />

<script>
import mixins from "@/mixins/mixins.js"
import moment from "moment"

export default {
  mixins: [mixins],
  data() {
    return {
      planInfo: {},
      planTypeId: null,
    }
  },
  mounted() {
    this.planInfo = this.$session.get("planInfo")
    this.planTypeId = this.$session.get("planTypeId")
    this.$nextTick(function () {
      this.fillPopup()
    })
  },
  methods: {
    fillPopup() {
      if (this.planInfo && this.planInfo.daysBetween < 0) {
        const positiveDays = this.planInfo.daysBetween * (-1)

        this.planInfo.monthValueText = this.maskMoney(this.planInfo.monthValue)
        this.planInfo.daysBetweenText = moment().subtract(positiveDays, "days").format("DD/MM/YYYY")
        this.planInfo.methodText = ""

        this.planInfo.periodText = this.planTypeId > 1 ? "Plus" : "Individual"
        this.planInfo.periodText += `${this.planInfo.period.substr(0, this.planInfo.period.length - 2)}`

        if (this.planInfo.method === "cartao") {
          this.planInfo.methodText = "Cartão"
        }
        if (this.planInfo.method === "boleto") {
          this.planInfo.methodText = "Boleto"
        }
      }
    },
    goToWallet() {
      this.$nextTick(() => this.$router.push("/wallet"))
    },
  },
}
</script>

<style lang="scss" src="./error403.scss" />